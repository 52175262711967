import React, { useContext, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

import style from "./JobFilters.module.css";
import { getFilters } from "../../../api/sinapsi.services";
import { CareersContext } from "../../CareersContext";
import { useConfig } from "../../ConfigContext";

export default function JobFilters() {
  const config = useConfig();
  const { t } = useTranslation();
  const jobsContext = useContext(CareersContext);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const request = getFilters(config).then((res) => {
      setFilters(res);
    });
  }, []);

  function onFilterChanged(details) {
    jobsContext.filtersChanged({
      checked: details.box.target.checked,
      filter: details.filter,
    });
  }

  const extractFilters = () => {
    return _.keys(filters).map((key) => {
      return (
        <div className={style.filterSection}>
          <h3 className={style.group}>{t(key)}</h3>
          {filters[key].map((item) => (
            <div className="ml-2">
              <input
                type="checkbox"
                onChange={(box) =>
                  onFilterChanged({
                    box: box,
                    filter: {
                      key: key,
                      name: key === "city" ? item.name : item.id,
                    },
                  })
                }
              />
              <span className={style.filterCriteria}>
                <span className="px-1">{item.name}</span>({item.count})
              </span>
            </div>
          ))}
        </div>
      );
    });
  };

  return (
    <div className={style.container}>
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        <Accordion.Item className={style.modifiedAccordion} eventKey="0">
          <Accordion.Header className={style.modifiedAccordionHeader}>
            <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
            <span className="px-2">{t("filters")}</span>
          </Accordion.Header>
          <Accordion.Body className={style.modifiedAccordion}>
            <div className={style.filterContainer}>{extractFilters()}</div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
