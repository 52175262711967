import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Nav, Navbar } from "react-bootstrap";

import styles from "./Header.module.scss";
import logo from "../../assets/logo-white.png";

export default function Header({ jobTitle, isJobHeader, isJobApplication }) {
  const { t } = useTranslation();

  function scrollToJobs() {
    const element = document.getElementById("jobs-list");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  function renderSubtitle() {
    if (isJobApplication) return t("jobApplication");
    if (isJobHeader) return;
    <button onClick={scrollToJobs} className="secondary mt-4">
      {t("viewOpenings")}
    </button>;
  }

  return (
    <div className={`${styles.header}`}>
      <div>
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand>
              <a
                className="nohover"
                href="https://www.rinf.tech/"
                target="_blank" rel="noreferrer"
              >
                <img className={styles.logo} src={logo}></img>
              </a>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link className="ms-2" href={`/`}>
                  <span>{t("home")}</span>
                </Nav.Link>
                <Nav.Link className="ms-2" href={`/jobs`}>
                  <span>{t("jobs")}</span>
                </Nav.Link>
                <button className="ms-5">
                  <a
                    className="px-2"
                    href="https://www.rinf.tech/contact/"
                    target="_blank" rel="noreferrer"
                  >
                    {t("contact")}
                  </a>
                </button>
                <a
                  className={`${styles.company} ms-2 px-2 align-middle`}
                  href="https://www.rinf.tech/"
                  target="_blank" rel="noreferrer"
                >
                  {t("companyName")}
                </a>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <h1 className="big-top-margin">
        {isJobHeader ? jobTitle : t("perfectJob")}
      </h1>
      {renderSubtitle()}
    </div>
  );
}
