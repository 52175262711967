import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import _ from "lodash";

import Header from "../../header/Header";
import { applyJob, getJob } from "../../../api/sinapsi.services";
import Footer from "../../footer/Footer";
import Navigator from "../../navigator/Navigator";
import { useConfig } from "../../ConfigContext";

export default function JobApplication() {
  const config = useConfig();
  const { t } = useTranslation();
  const { jobId } = useParams();
  let links = [];
  if (jobId === undefined) {
    links = [
      { link: "/jobs", label: "jobListing" },
      { link: "jobs", label: "openJobApplication" },
    ];
  }
  else {
    links = [
      { link: "/jobs", label: "jobListing" },
      { link: `/jobs/${jobId}`, label: "jobDetails" },
      { link: "jobs", label: "jobApplication" },
    ];
  }
  const [jobDetails, setJobDetails] = useState({});
  const [applicationDetails, setApplicationDetails] = useState({
    GDPR : "Off"
  });
  const [state, setState] = useState({
    validated: false,
    submitting: false,
    submitted: false,
    showModal: false,
    invalidCaptcha: false,
  });

  useEffect(() => {
    loadCaptchaEnginge(6, "#1da148", "#FFFFFF");
  }, []);

  useEffect(() => {
    if (jobId === undefined) {
      setJobDetails({
        title: "Open Application"
      });
    }
    else {
      getJob(config, jobId).then((res) => {
        setJobDetails({ ...res });
      });
    }
  }, [jobId]);

  function submitApplication(event) {
    const validCaptcha = validateCaptcha(
      applicationDetails.user_captcha_input,
      false
    );
    const form = document.getElementById("applicationForm");
    if (!validCaptcha) {
      document.getElementById("user_captcha_input").value = "";
    }
    if (form.checkValidity() === false || !validCaptcha) {
      setState({
        ...state,
        submitting: false,
        validated: true,
        invalidCaptcha: !validCaptcha,
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      setState({ ...state, submitting: true });
      const result = applyJob(config,
        jobId,
        _.omit(applicationDetails, "user_captcha_input")
      )
        .then(() => {
          setState({ ...state, submitting: false, submitted: true });
        })
        .catch((ex) => {
          setState({ ...state, showModal: true });
        });
    }
  }

  function onInputChanged(input) {
    var fieldValue = input.target.value;
    if (input.target.type === "file") {
      fieldValue = input.target.files[0].name;
      const file = input.target.files[0];
      const reader = new FileReader();
      reader.onloadend = function () {
        var b64 = reader.result.replace(/^data:.+;base64,/, "");
        setApplicationDetails({
          ...applicationDetails,
          [input.target.id + "FileName"]: fieldValue,
          [input.target.id + "Content"]: b64,
        });
      };
      reader.readAsDataURL(file);
    } else if(input.target.id === "gdprPeriod"){
        setApplicationDetails({
          ...applicationDetails,
          "GDPR": applicationDetails.GDPR === "Off" ? "Extended" : "Off",
        });
    } else {
      setApplicationDetails({
        ...applicationDetails,
        [input.target.id]: fieldValue,
      });
    }
  }

  function handleClose() {
    setState({ ...state, showModal: false });
  }

  if (state.submitted && !state.showModal) {
    return (
      <div className="text-center mt-4">
        <FontAwesomeIcon
          className="display-3"
          icon={faCheckCircle}
        ></FontAwesomeIcon>
        <h2 className="mt-3">{t("applicationSubmittedTitle")}</h2>
        <p>{t("applicationSubmittedDescription")}</p>
      </div>
    );
  }

  return (
    <div>
      <Header
        isJobHeader={true}
        jobTitle={jobDetails.title}
        isRemote={jobDetails.workTypes}
        isFullTime={jobDetails.contractDuration === "Full time"}
        isJobApplication={jobId !== undefined}
      ></Header>
      <Container className="mt-4">
        <Navigator links={links}></Navigator>
        <Modal show={state.showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("jobApplyErrorHeader")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t("jobApplyErrorAlreadyApplied")}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Form
          id="applicationForm"
          validated={state.validated}
          onSubmit={submitApplication}
          className="mt-4"
        >
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>{t("firstName")} *</Form.Label>
                <Form.Control
                  disabled={state.submitting}
                  required={true}
                  onChange={onInputChanged}
                  type="text"
                  placeholder="First Name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>{t("email")} *</Form.Label>
                <Form.Control
                  disabled={state.submitting}
                  required={true}
                  onChange={onInputChanged}
                  type="email"
                  placeholder="name@example.com"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="linkedin">
                <Form.Label>{t("linkedin")}</Form.Label>
                <Form.Control
                  disabled={state.submitting}
                  onChange={onInputChanged}
                  type="text"
                  placeholder={t("linkedinProfile")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="captcha">
                <Form.Label>{t("captcha")} *</Form.Label>
                <LoadCanvasTemplate
                  reloadText={t("reloadCaptcha")}
                  reloadColor="#2980b9"
                />
                <Form.Control
                  required
                  className="mt-1"
                  disabled={state.submitting}
                  onChange={onInputChanged}
                  id="user_captcha_input"
                  name="user_captcha_input"
                  type="text"
                  isInvalid={state.invalidCaptcha}
                  placeholder={t("enterCaptcha")}
                />
              </Form.Group>
              <div className="col mt-3"></div>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="surname">
                <Form.Label>{t("lastName")} *</Form.Label>
                <Form.Control
                  disabled={state.submitting}
                  required
                  onChange={onInputChanged}
                  type="text"
                  placeholder="Last Name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>{t("phone")} *</Form.Label>
                <Form.Control
                  type="text"
                  disabled={state.submitting}
                  required={true}
                  onChange={onInputChanged}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="cv">
                <Form.Label>{t("resume")} *</Form.Label>
                <Form.Control
                  disabled={state.submitting}
                  required={true}
                  type="file"
                  accept=".doc,.docx,.pdf"
                  onChange={onInputChanged}
                />
              </Form.Group>
            </Col>
          </Row>
          <h3 className="mt-4">{t("gdprConsentTitle")}</h3>
          {jobId === undefined ? (
            <Form.Group className="mb-3" controlId="gdprPeriod">
              <Form.Label className="d-block">
                <Form.Check.Input
                  disabled={state.submitting}
                  type={"checkbox"}
                  required={true}
                  onChange={onInputChanged}
                />
                {t("openGdprConsent")}
                <a
                  target="_blank"
                  href="https://www.rinf.tech/terms-conditions/recruitment-privacy-notice/"
                  className="fw-bold nohover dark d-inline-block" rel="noreferrer"
                >
                  &nbsp;
                  {t("privacyNotice")}
                  &nbsp;
                </a>
                {t("gdprConsent2")}
              </Form.Label>
            </Form.Group>
          ) : (
            <>
              <Form.Group className="mb-3" controlId="gdpr">
                <Form.Label className="d-block">
                  <Form.Check.Input
                    disabled={state.submitting}
                    type={"checkbox"}
                    required={true}
                    onChange={onInputChanged}
                  />
                  {t("gdprConsent")}
                  <a
                    target="_blank"
                    href="https://www.rinf.tech/terms-conditions/recruitment-privacy-notice/"
                    className="fw-bold nohover dark d-inline-block" rel="noreferrer"
                  >
                    &nbsp;
                    {t("privacyNotice")}
                    &nbsp;
                  </a>
                  {t("gdprConsent2")}
                </Form.Label>
              </Form.Group>
              <Form.Group className="mb-3" controlId="gdprPeriod">
                <Form.Label className="d-block">
                  <Form.Check.Input
                    disabled={state.submitting}
                    type={"checkbox"}
                    onChange={onInputChanged}
                  />
                  {t("gdprConsentPeriod")}
                </Form.Label>
              </Form.Group>
            </>
          )}
        </Form>
        <div className="mt-5 mb-2 text-center">
          <button
            onClick={submitApplication}
            className="mx-2"
            variant="success"
          >
            {t("submit")}
          </button>
          {jobId === undefined ? (
            <Link className="nohover d-inline-block" to={`/jobs`}>
            <button className="third mx-2">{t("cancel")}</button>
          </Link>
          ):(
            <Link className="nohover d-inline-block" to={`/jobs/${jobId}`}>
            <button className="third mx-2">{t("cancel")}</button>
          </Link>
          )}
        </div>
      </Container>
      <Footer showJobs={true}></Footer>
    </div>
  );
}
