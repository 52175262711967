import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { Link, useParams } from "react-router-dom";

import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Loader from "../../loader/Loader";
import styles from "./JobDetails.module.scss";
import { getJob } from "../../../api/sinapsi.services";
import Navigator from "../../navigator/Navigator";
import jobLevel from "../../../assets/joblevel.png";
import locationIcon from "../../../assets/location.png";
import jobTypeIcon from "../../../assets/jobtype.png";
import remoteJob from "../../../assets/remotejob.png";
import { useConfig } from "../../ConfigContext";

export default function JobDetails() {
  const config = useConfig();
  const { t } = useTranslation();
  const { jobId } = useParams();
  const links = [
    { link: "/jobs", label: "jobListing" },
    { link: `/jobs/${jobId}`, label: "jobDetails" },
  ];
  const [jobDetails, setJobDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getJob(config, jobId).then((res) => {
      setJobDetails({
        ...res,
        isFullTime: res.contractDuration === "Full time",
      });
      setLoading(false);
    });
  }, [jobId]);

  function buildDescription(details) {
    return details.description?.paragraphs.map((paragraph) => {
      if (paragraph.paragraphType === 0) {
        const lines = paragraph.content.split(/\r?\n/);
        return (
          <div>
            <h3 className="mt-4">{paragraph.title}</h3>
            {lines.map((line) => (
              <div className="mt-1 text-break text-justify">{line}</div>
            ))}
          </div>
        );
      } else {
        return (
          <div>
            <h3 className="mt-4">{paragraph.title}</h3>
            <ul>
              {paragraph.items.map((item) => (
                <li className="mt-1 text-break text-justify">{item}</li>
              ))}
            </ul>
          </div>
        );
      }
    });
  }

  return (
    <div>
      <Header
        isJobHeader={true}
        jobTitle={jobDetails.title}
        isRemote={jobDetails.workTypes}
        isFullTime={jobDetails.isFullTime}
        canApply={jobDetails.canApply}
        jobId={jobId}
      ></Header>
      {loading ? (
        <Loader loading={loading}></Loader>
      ) : (
        <div>
          <Container className="mb-4">
            <Navigator links={links}></Navigator>
            <Row>
              <Col>
                <div className={`${styles.container} pl-2`}>
                  <div>
                    <h3>{t("jobInfo")}</h3>
                    <h4>{jobDetails.jobInfoTitle}</h4>
                    <div className={styles.detailsSection}>
                      <div className={styles.section}>
                        {t("experienceLevel")}
                      </div>
                      <div>
                        <img src={jobLevel}></img>
                        {jobDetails.seniority}
                      </div>
                    </div>
                    <div className={styles.detailsSection}>
                      <div className={styles.section}>{t("workTime")}</div>
                      <div>
                        <img src={jobTypeIcon}></img>
                        {jobDetails.isFullTime ? t("fullTime") : t("partTime")}
                      </div>
                    </div>
                    <div className={styles.detailsSection}>
                      <div className={styles.section}>{t("location")}</div>
                      <div>
                        <img src={locationIcon}></img>
                        {jobDetails.country}
                        {jobDetails.city ? <span>,&nbsp;</span> : null}
                        {jobDetails.city}
                      </div>
                    </div>
                    <div className={styles.detailsSection}>
                      <div className={styles.section}>{t("workType")}</div>
                      <div>
                        <img src={remoteJob}></img>{" "}
                        {jobDetails.workTypes?.map((w) => (
                          <span>{w}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={9} className={styles.left}>
                <div className={styles.jobSection}>
                  <h3>{t("jobDescription")}</h3>
                  <p className="text-justify">{jobDetails.abstract}</p>
                </div>
                {buildDescription(jobDetails)}
                <div className="mt-4">
                  {jobDetails.canApply ? (
                    <Link
                      className="nohover d-inline-block me-3"
                      to={`/jobs/${jobId}/apply`}
                    >
                      <button>{t("interested")}</button>
                    </Link>
                  ) : null}
                  <Link className="nohover d-inline-block" to={`/jobs`}>
                    <button className="third">{t("goback")}</button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
          <Footer showJobs={true}></Footer>
        </div>
      )}
    </div>
  );
}
