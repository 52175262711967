import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceFrown,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

import { getNps, submitNps } from "../../api/sinapsi.services";
import logo from "../../assets/logorinf-colour.png";
import styles from "./Nps.module.scss";
import Loader from "../loader/Loader";
import { useConfig } from "../ConfigContext";

export default function Nps() {
  const { t } = useTranslation();
  const config = useConfig();
  const { npsId } = useParams();
  const [state, setState] = useState({
    loadingNps: false,
    validated: false,
    submitting: false,
    submitted: false,
  });
  const [npsDetails, setNpsDetails] = useState({});
  const [userNps, setUserNps] = useState({
    overallExperienceScore: null,
    improvementNotes: null,
  });
  const requiredNpsFields = ["OverallExperienceScore", "TAExperienceScore"];

  useEffect(() => {
    setState({ ...state, loadingNps: true });
    getNps(config, npsId).then((result) => {
      setNpsDetails(result);
      setState({ ...state, loadingNps: false });
    });
  }, {});

  const renderInvalidNps = () => {
    return (
      <div className="text-center">
        <FontAwesomeIcon className="display-3 mb-2" icon={faFaceFrown} />
        <h2>{t("invalidNps")}</h2>
        <div>{t("invalidNpsMessage")}</div>
      </div>
    );
  };

  const renderNpsScale = (fieldId, maxScale, required, validated) => {
    const scale = [];

    for (var index = 1; index <= maxScale; index++) {
      const experienceIndex = index;
      const item = (
        <button
          className={`${styles.npsScale} ${
            experienceIndex === userNps[fieldId] ? styles.npsScaleSelected : ""
          } inline-block mx-1 my-1`}
          onClick={() => setUserNps({ ...userNps, [fieldId]: experienceIndex })}
        >
          {index}
        </button>
      );
      scale.push(item);
    }

    return (
      <div
        className={
          required && validated && !userNps[fieldId] ? "required" : null
        }
      >
        <div>{scale}</div>
        <div></div>
      </div>
    );
  };

  const handleChange = (prop, event) => {
    setUserNps({ ...userNps, [prop]: event.target.value });
  };

  const renderValidNps = () => {
    if (!npsDetails.canComplete) {
      return (
        <div className="text-center">
          <FontAwesomeIcon className="display-3 mb-2" icon={faClipboardCheck} />
          <h2 className="text-center">{t("alreadySubmittedNps")}</h2>
        </div>
      );
    }
    return (
      <div>
        <h2>{t("npsGreeting", { who: npsDetails.name })}</h2>
        <p className="text-justify">{t("npsMessage")}</p>
        <div className="mt-4">
          <h3>{t("npsExperienceScaleDescription")} *</h3>
          {renderNpsScale("OverallExperienceScore", 10, true, state.validated)}
        </div>
        <div className="mt-4">
          <h3>{t("npsExperienceScaleDescription2")} *</h3>
          {renderNpsScale("TAExperienceScore", 10, true, state.validated)}
        </div>
        <Form
          id="applicationForm"
          validated={state.validated}
          onSubmit={submitUserNps}
          className="mt-4"
        >
          <Form.Group className="mb-3" controlId="experienceNotes">
            <Form.Label>{t("npsExperienceDescription")}</Form.Label>
            <Form.Control
              disabled={state.submitting}
              onChange={(event) => handleChange("experienceNotes", event)}
              as="textarea"
              rows={5}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="experienceNotes">
            <Form.Label>{t("npsExperienceImprovement")}</Form.Label>
            <Form.Control
              disabled={state.submitting}
              onChange={(event) => handleChange("improvementNotes", event)}
              as="textarea"
              rows={5}
            />
          </Form.Group>
        </Form>
        {npsDetails.canComplete ? (
          <button className=" mb-4" onClick={submitUserNps}>
            {t("submitGeneric")}
          </button>
        ) : null}
      </div>
    );
  };

  const submitUserNps = (event) => {
    const form = document.getElementById("applicationForm");
    if (
      form.checkValidity() === false ||
      _.some(requiredNpsFields, (field) => !userNps[field])
    ) {
      setState({ ...state, submitting: false, validated: true });
      event.preventDefault();
      event.stopPropagation();
    } else {
      setState({ ...state, submitting: true });
      const result = submitNps(config, npsId, userNps)
        .then(() => {
          setState({ ...state, submitting: false, submitted: true });
        })
        .catch((ex) => {
          setState({ ...state, showModal: true });
        });
    }
  };

  if (state.loadingNps) {
    return <Loader loading={state.loadingNps}></Loader>;
  }
  if (state.submitted) {
    return (
      <div className="text-center my-4">
        <FontAwesomeIcon className="display-3 mb-2" icon={faClipboardCheck} />
        <div>{t("npsSubmitted")}</div>
      </div>
    );
  }
  return (
    <Container>
      <div className="my-4">
        <img className={styles.logo} src={logo}></img>
      </div>
      {npsDetails.invalidIdentifier ? renderInvalidNps() : renderValidNps()}
    </Container>
  );
}
