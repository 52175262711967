import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import style from "./JobAlertUnsubscribe.module.css";

import Form from "react-bootstrap/Form";
import { Link, useParams } from "react-router-dom";
import { Container, Modal } from "react-bootstrap";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Navigator from "../../navigator/Navigator";
import { unsubscribe } from "../../../api/sinapsi.services";
import { useConfig } from "../../ConfigContext";

export default function JobAlertUnsubscribe() {
  const config = useConfig();
  const { t } = useTranslation();
  const { token } = useParams();
  const [subToken, setSubToken] = useState();
  const [state, setState] = useState({
    validated: false,
    submitting: false,
    submitted: false,
    showModal: false,
  });

  const links = [];
  
  useEffect(() => {
    setSubToken(token);
  }, []);

  function handleClose() {
    setState({ ...state, showModal: false });
  }

  function submitUnsubscribe(event) {
    setState({ ...state, submitting: true });
    const result = unsubscribe(config,
      subToken
    )
      .then(() => {
        setState({ ...state, submitting: false, submitted: true });
      })
      .catch((ex) => {
        setState({ ...state, showModal: true });
      });
  }

  if (state.submitted && !state.showModal) {
    return (
      <div className="text-center mt-4">
        <FontAwesomeIcon
          className="display-3"
          icon={faCheckCircle}
        ></FontAwesomeIcon>
        <h2 className="mt-3">Unsubscribed</h2>
        <p>You have successfully unsubscribed from job alerts.</p>
      </div>
    );
  }

  return (
    <div>
      <Header
        isJobHeader={true}
        jobTitle={"Unsubscribe from Job Alerts"}
        isJobApplication={false}
      ></Header>
      <Container className={`mt-4 ${style.container}`}>
        <Navigator links={links}></Navigator>
        <Modal show={state.showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Could not unsubscribe</Modal.Title>
          </Modal.Header>
          <Modal.Body>There was an issue while unsubscribing to job alerts. Please try again.</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        
        <Form.Label>Are you sure you want to unsubscribe?</Form.Label>
        <div className="mt-5 mb-2 text-center">
          <button
            onClick={submitUnsubscribe}
            className="mx-2"
            variant="success"
          >
            Unsubscribe
          </button>
          <Link className="nohover d-inline-block" to={`/jobs`}>
            <button className="third mx-2">{t("cancel")}</button>
          </Link>
        </div>
      </Container>
      <Footer showJobs={true}></Footer>
    </div>
  );
}
